html{
  height: 100%;
}
body{
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  color: #2e2e38;
  font-size: 14px !important;
  font-display: swap;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f5f5 !important;
}

.main-container{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.red-text {
    color: #F44336 !important;
}

.teal-text {
    color: #008073 !important;
}

.cyan-text {
    color: #00bcd4 !important;
}

.indigo-text {
    color: #3f51b5 !important;
}

.purple-text {
    color: #9c27b0 !important;
}

.orange-text {
    color: #ff9800 !important;
}

.brown-text {
    color: #795548 !important;
}

.grey-text {
    color: #666 !important;
}

.yellow-text {
    color: #ffe600 !important;
}

.dark-gray-text {
    color: #2e2e38 !important;
}