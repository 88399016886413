.content-container{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    height: calc(100% - 110px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.content-container .main-content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 80%;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    padding: 0;
    font-display: swap;
    margin: 0 10%;
}

.content-container .main-content-container .action-container {
    text-align: right;
    padding: 10px;
}

.content-container .main-content-container .action-container .ms-Button .ms-Button-flexContainer .ms-Button-textContainer .ms-Button-label {
    color: #000 !important;
}

.content-container .main-content-container .md-preview {
    padding: 10px;
    background: white;
    margin-top: 15px;
    min-height: 400px;
}
.content-container .main-content-container .md-editor {
    padding: 10px;
    margin-top: 15px;
}

@media only screen and (max-width: 768px) {
    .content-container{
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .content-container .main-content-container { 
        /* For mobile phones: */
        width: calc(100% - 20px);
        margin: 0 10px;
    }
}
