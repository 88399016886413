.header-container {
    margin: 0;
    padding: 5px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    height: 55px;
    background: #2e2e38;
    box-shadow: 0 2px 5px 0 #e0e0e0, 0 2px 10px 0 #e0e0e0;
    webkit-box-shadow: 0 2px 5px 0 #e0e0e0, 0 2px 10px 0 #e0e0e0;
    -moz-box-shadow: 0 2px 5px 0 #e0e0e0, 0 2px 10px 0 #e0e0e0;
    display: flex;
    width: 100%;
    color: #fff;
}

.header-container .header-menu-container{
    width: 50%;
    text-align: right;
}

.header-container .header-menu-container .login-container{
    text-align: right;
    display: inline-block;
    padding-left: 20px;
    border-left: 1px solid #e0e0e0;
    margin-left: 10px;
}

.header-container .header-menu-container .login-container .ms-Button {
    color: #fff !important;
    background-color: transparent !important;
}

.header-container .header-menu-container .login-container .ms-Button-label {
    color: #fff !important;
}

.header-container .header-menu-container .login-container .ms-Icon {
    color: #fff !important;
}

.ms-Button {
    background-color: transparent !important;
}
.ms-Icon {
    color: #fff !important;
}

.ms-Button-label {
    color: #fff !important;
}

.ms-Panel-commands .ms-Icon {
    color: #000 !important;
}

.ms-Persona {
    margin-top: 5px;
}

.custom-panel-footer {
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
    padding-bottom: 20px;
}
.custom-panel-footer .ms-Icon {
    color: #000 !important;
}

.custom-panel-footer .ms-Button-label {
    color: red !important;
}

.header-container .logo-container {
    width: 100%;
    padding-top: 10px;
}

.header-container .logo-container .header-text {
    margin-left: 5px;
}

.header-container .logo-container h4 , h6{
    letter-spacing: 1px;
    color: #fff;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    cursor: default;
    font-display: swap;
    padding: 0;
    margin: 0;
    line-height: normal;
}

.header-container .logo-container h4 , h6 a{
    font-size: 25px;
    color: #fff;

    @media only screen and (max-width:510px){
        font-size: 20px;
        line-height: 2.1;
    }

    @media only screen and (max-width:420px){
        font-size: 16px;
        line-height: 2.5;
    }
    @media only screen and (max-width:360px){
        font-size: 14px;
        line-height: 2.7;
    }
}
.header-container .logo-container h4 , h6 a:hover{
    text-decoration: none;
    color: #fff;
}
.header-container .logo-container h4 , h6 a:active{
    text-decoration: none;
    color: #fff;
}
.header-container .logo-container h4 , h6 a:visited{
    text-decoration: none;
    color: #fff;
}
.header-container .logo-container h4 , h6 a:focus{
    text-decoration: none;
    color: #fff;
}

.header-container .logo-container span{
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    color: #fff;
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 1px;
    padding-top: 15px;
}

.header-container .logo-container h6{
    font-size: 14px;
    letter-spacing: 1px;
    font-display: swap;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    color: rgb(50, 49, 48);
    font-weight: 200;
}

.file-list-container {
    list-style: none;
    font-size: 12px;
    overflow-x: hidden;
    margin: 20px 0;
    padding: 0;
}

.file-list-container li {
    padding: 10px;
    font-size: 12px;
    list-style-position:inside;
    white-space: nowrap;
    overflow: hidden;
    color: rgb(50, 49, 48);
    text-overflow: ellipsis;   
    cursor: pointer; 
}

.file-list-container li:nth-child(odd) {
    background-color: rgb(237, 235, 233);
}