.footer-container {
  width: 100%;
  padding: 20px;
  background: #2e2e38;
  box-sizing: border-box;
  color: #f5f5f5;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
}

.footer-container .row-1 {
  text-align: center;
}

.footer-container .row-2 {
  text-align: center;
  font-size: 10px;
}